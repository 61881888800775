import React from "react";
import { navigate } from "gatsby";
import withLocale from "./withLocale";
import PropTypes from "prop-types";

class Redirect extends React.Component {
  componentDidMount() {
    const { locale } = this.props;
    const currentPath = window.location.pathname; // "guide/case-studies/honduras-map"
    const nextPath = `/${locale}/${currentPath}`;
    console.log("redirect to", nextPath);
    navigate(nextPath, { replace: true });
  }

  render() {
    return <React.Fragment />;
  }
}

export default withLocale(Redirect);

Redirect.propTypes = {
  locale: PropTypes.string.isRequired
};
